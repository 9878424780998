import React from "react";
import "./ImageLayoutEditor.scss";

function ImageLayoutEditor() {
  return (
    <div>
      <ul>
        <li className="lhsItems">Draw Layout</li>
        <li className="lhsItems">Select Layout</li>
        <li className="lhsItems">Delete</li>
        <li className="lhsItems">get Layouts</li>
        <li className="lhsItems">clear Layouts</li>
      </ul>
    </div>
  );
}

export default ImageLayoutEditor;
