import React from "react";

function PDFRemoteViewer(props) {
  const fullFilePath =
    "https://dev.genai-exp.indegene.ai/middleware/downloadAttachments/" +
    props?.file.pdf_file_path;
  return (
    <div>
      <h1>PDF Viewer</h1>
      <iframe
        title="PDF Viewer"
        width="100%"
        height="600"
        src={`${fullFilePath}#view=fitH`}
        id="pdf-Iframe"
      />
    </div>
  );
}

export default PDFRemoteViewer;
